import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const StyledSection = styled.section`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto 1fr;
  margin-bottom: 2rem;
  @media (max-width: 400px) {
    & {
      grid-template-columns: auto;
      justify-items: center;
    }
  }
`

const StyledH2 = styled.h2`
  margin-top: 0;
`

export default () => {
  const data = useStaticQuery(graphql`
    fragment servicesImages on File {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    query {
      ingrown: file(
        relativePath: { eq: "services/ingrown-toenails-treated-perth.jpg" }
      ) {
        ...servicesImages
      }
      discoloured: file(
        relativePath: { eq: "services/discoloured-toenails-treated-perth.jpeg" }
      ) {
        ...servicesImages
      }
      thickened: file(
        relativePath: { eq: "services/thickened-toenails-treated-perth.jpeg" }
      ) {
        ...servicesImages
      }
      fungal: file(
        relativePath: { eq: "services/fungal-toenails-treated-perth.png" }
      ) {
        ...servicesImages
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Common Toenail Troubles" />
      <ServicesLayout title="Common Toenail Troubles">
        <StyledSection>
          <Img
            fixed={data.ingrown.childImageSharp.fixed}
            alt="Ingrowing treatment Perth"
          />
          <div>
            <StyledH2>Ingrowing Toenails</StyledH2>
            <p>
              Ingrowing nails are very painful condition which can repeatedly
              become infected.
            </p>
            <p>
              There are multiple causes for the issue but the solution involves
              removing the edge of offending nail conservatively or permanantly
              through a short procedure by your podiatrist.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.fungal.childImageSharp.fixed}
            alt="Fungal toenails treatment Perth"
          />
          <div>
            <StyledH2>Fungal Toenails</StyledH2>
            <p>
              This is one of the most common but persistent nail complaints. It
              occurs through infection with fungal spores picked up in showers,
              pools, garden soil or another person with the infection.
            </p>
            <p>
              Treatment involves the podiatrist gently removing as much of the
              infected part of the nail as possible then treating the remaining
              good nail with an antifungal agent.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.thickened.childImageSharp.fixed}
            alt="Thickened toenails treatment Perth"
          />
          <div>
            <StyledH2>Thickened Toenails</StyledH2>
            <p>
              Thick toe nails ("Onychogryphosis") is usually caused through
              trauma of the nail plate.
            </p>
            <p>
              It is a permanent deformity of the nail, however we can painlessly
              remove excess nail and file it down to look just as a normal nail
              should.
            </p>
          </div>
        </StyledSection>
        <StyledSection>
          <Img
            fixed={data.discoloured.childImageSharp.fixed}
            alt="Discoloured toenails treatment Perth"
          />
          <div>
            <StyledH2>Discoloured Toenails</StyledH2>
            <p>
              There are a variety of reasons as to why a nail may be discolored.
              There could be bleeding underneath the nail, a bruise,
              pigmentation, melanoma, skin infection, fungal infection etc.
            </p>
            <p>
              The podiatrist will investigate the cause and be able to treat or
              advise accordingly.
            </p>
          </div>
        </StyledSection>
        <BookButton text="I want my toenails healthy again!" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
